<template>
    <div class="share_app">
        <ul class="share_count">
            <li class="share_count_li">
                <img src="../assets/logo@2x.png" alt="" style="width:100px;height:35px">
            </li>
            <li class="share_title">
                听专业的人做专业的事，让专业的人帮你做专业的事。
            </li>
        </ul>
        <button class="share_btn" @click="downloadApp">下载App</button>
    </div>
</template>
<script>
export default {
    created() {
        window.document.title = '分享app'
    },
    methods: {
        downloadApp() {
            // 通用协议地址
            // iOS 下载地址
            var iOSDownloadUrl = "https://apps.apple.com/cn/app/%E5%90%AC%E8%82%A1%E7%A5%A8/id1453009085";
            // Android 下载地址
            var androidDownloadUrl = "https://a.app.qq.com/o/simple.jsp?pkgname=com.company.listenStock"

            var commSchemeUrl = 'https://api.tinggupiao.com.cn/';
            let u = navigator.userAgent
            var isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if (isIos) {
                console.log(commSchemeUrl);
                window.location.href = commSchemeUrl;
            } else {
                console.log(commSchemeUrl);
                commSchemeUrl = 'scxlistenstock://' + 'action:9999?url=127.0.0.1&&title=文章详情&&fileName=ArticleDetail&&firstLoad={"id":' + this.articleId + "}"
                window.location.href = commSchemeUrl;
            }
            // 3秒后没打开，直接跳转下载页面
            var appDownload = setTimeout(function () {
                console.log("下载app");
                if (isIos) {
                    window.location.href = iOSDownloadUrl;
                } else {
                    window.location.href = androidDownloadUrl;
                }
            }, 3000);
            if (!isIos) {
                document.addEventListener('visibilitychange webkitvisibilitychange', function () {
                    // 页面隐藏，打开app，清除下载
                    if (document.hidden || document.webkitHidden) {
                        clearTimeout(appDownload)
                    }
                })
                window.addEventListener('pagehide', function () {
                    clearTimeout(appDownload)
                })
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.share_app {
    padding: 0px 16px 0px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .share_count {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 164px;
        list-style: none;
        margin-block-start: 0;
        margin-block-end: 0;
        padding-inline-start: 0;

        .share_count_li {
            width: 120px;
            height: 120px;
            background: #FA2E2E;
            border-radius: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .share_title {
            width: 234px;
            text-align: center;
            padding-top: 32px
        }
    }

    .share_btn {
        width: 100%;
        border: 0px;
        height: 44px;
        background: #EF0923;
        border-radius: 22px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        margin-top: 68px;
    }
}
</style>